<template>
  <component
    v-if="false === planeLoading"
    v-bind:is="currentPlane"
    :change="changeSeat"
    @changeSeat="changeSeat = $event"
    :seats="planeAirSeats"
    :type="planeType"
    @update="submitAndRedirect($event)"
  >
  </component>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UpgradeSeat",
  data: () => {
    return {
      changeSeat: false
    };
  },
  watch: {
    planeLoading: {
      handler() {
        if (this.planeLoading === "empty") {
          this.getPlane();
        }
      },
      immediate: true
    }
  },
  created() {
    this.getPlane();
    if (this.layoutLoading === "empty") {
      this.actionLayout();
    }
  },
  computed: {
    ...mapState("flight", {
      flightDetailsLoading: state => state.flightDetailsLoading,
      flightDetails: state => state.flightDetails,
      flightLoading: state => state.flightLoading,
      flight: state => state.flight,
      planeLoading: state => state.planeLoading,
      planeAirSeats: state => state.planeAirSeats,
      planeType: state => state.planeType
    }),
    ...mapState("order", {
      layoutLoading: state => state.layoutLoading
    }),

    currentPlane() {
      if (this.planeLoading === false) {
        return () => import("@/components/planes/" + this.planeType + ".vue");
        // import("@/components/planes/A320.vue");
      }
      return false;
    }
  },
  methods: {
    ...mapActions("flight", {
      getPlane: "plane",
      updateSeat: "updateSeat"
    }),
    ...mapActions("order", {
      actionLayout: "layout",
      updateOrder: "updateOrderState"
    }),
    async submitAndRedirect(payloadData) {
      if (payloadData.paymentType !== 1) {
        window.location.href =
          window.location.href.split("?")[0] +
          "?payment=upgrade-success&guid=" +
          payloadData.guid +
          "&selected=" +
          payloadData.selected +
          "&ptype=" +
          payloadData.paymentType;
        return;
      }
      await this.updateOrder({
        guid: payloadData.guid,
        state: "waitingForPayment"
      });
      const payload = {
        amount: {
          value: payloadData.price,
          currency: "RUB"
        },
        paymentMethod: {
          type: "bankCard"
        },
        successUri:
          window.location.href.split("?")[0] +
          "?payment=upgrade-success&guid=" +
          payloadData.guid +
          "&selected=" +
          payloadData.selected +
          "&ptype=" +
          payloadData.paymentType,
        errorUri:
          window.location.href.split("?")[0] +
          "?payment=upgrade-error&guid=" +
          payloadData.guid +
          "&selected=" +
          payloadData.selected +
          "&ptype=" +
          payloadData.paymentType,
        description: "testPayment"
      };
      const personalData = {
        email: payloadData.email,
        phone: payloadData.phone
      };
      if (personalData) {
        const receipt = {
          items: [
            {
              quantity: 1,
              amount: {
                value: payloadData.price,
                currency: "RUB"
              },
              vatCode: 4, //TODO: what's real value?
              description: "testPayment"
            }
          ]
        };
        if (personalData.email) {
          Object.assign(receipt, { email: personalData.email });
        }
        if (personalData.phone) {
          Object.assign(receipt, { phone: personalData.phone });
        }
        Object.assign(payload, { receipt });
      }

      const data = await this.axios
        .post("/offline-payment-accumulator/payments/create", payload)
        .then(response => response.data.result);
      if (data.confirmationUri) {
        window.location.href = data.confirmationUri;
      }
    }
  }
};
</script>

<style scoped></style>
